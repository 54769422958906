// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function getData(entity_type, field_to_search, op=null, entityID=null) {
    return new Promise((resolve, reject) => {
        let newRef = firebase.firestore().collection(entity_type);

        if (op)
            newRef = newRef.where(field_to_search, op, entityID).get();
        else
            newRef = newRef.doc(field_to_search).get()

        if(newRef) {
            resolve(newRef);
        }
        else {
            reject("The write operation failed");
        }
    });
}

/*
    Insert data at the given location
 */
function setData(data, entity_type, entityID) {
    firebase.firestore().collection(entity_type).doc(entityID).update(
       data
    , { merge: true })
    .catch((error) => {
        // The document probably doesn't exist.
        console.log(error); 
        alert("Error updating document: ", error);
    });
    
} 

export {getData, setData};

export const firebase_instance = firebase;
export const firebase_auth = firebase.auth();
export const firestore = firebase.firestore();
export const firebase_database = firebase.database();
