const delta = {
    "richardlin3": [
        61484, 
        61322, 
        61324, 
        61325, 
        61328, 
        66456, 
        66210, 
        61336, 
        66457, 
        61341, 
        61342, 
        61343, 
        66208, 
        61346, 
        61603, 
        61348, 
        61617, 
        66217, 
        61611, 
        61356, 
        61485, 
        61360, 
        61361, 
        61362, 
        61620, 
        61622, 
        61624, 
        61497, 
        61371, 
        61500, 
        61501, 
        61376, 
        61378, 
        66628, 
        61366, 
        61512, 
        61513, 
        61387, 
        61517, 
        61392, 
        61395, 
        61405, 
        61490, 
        61407, 
        61411, 
        61414, 
        61416, 
        61499, 
        61384, 
        61353
    ], 
    "karger": [
        61393, 
        61377, 
        61378, 
        66595, 
        61350, 
        61351, 
        61387, 
        61324, 
        66225, 
        61394, 
        61347, 
        61333, 
        61367, 
        61336, 
        66457, 
        61402, 
        66461, 
        61395
    ]
}
export default delta;