import emailjs from '@emailjs/browser';
import { firestore } from "../utils/database";

emailjs.init("p-tc2UVQHUMvyHygU");

const sendEmail = async (title, content, recipient) => {
    // if the last messags is sent less than a minute, not send this email to avoid cluttering users' inbox
    const notiRef = firestore.collection('emailNotification').doc(recipient.id);
    const notiData = await notiRef.get();

    if(notiData.data() && notiData.data().updatedAt && Date.now() - notiData.data().updatedAt < 60 * 1000) {
        return; 
    }

    // send email to user
    emailjs.send('service_341y0w2', 'template_zq763js', {
      'to_name': recipient.name,
      'to_email': recipient.email,
      'from_name': "Who2chat",
      'title': title, 
      'message': content,
    }, 'p-tc2UVQHUMvyHygU')
      .then((result) => {
        console.log("update email sent");
        // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        notiRef.set({
            "message": title,
            "updatedAt": Date.now(),
        }, {merge: true})
        // TODO update DB when is the last time sent the message. 
      }, (error) => {
          console.log(error.text);
      });
}

/*
Hello {{to_name}},

Thanks for signing up for the HCI social hour tomorrow!!  

The social hour will be happening tomorrow and we noticed you haven't signed up to Who2chat yet. If you are planning to attend the virtual social hour, please complete your profile at Who2chat (https://who2chat.com) today and look through other attendees. Doing it in advance allows others to know that you're coming and planning ahead :)

Reg code for the website: HCI

* Who2chat is a "virtual coffee hour" app that allows searching others' researchers who you should meet and in-app video call within the interface all in one interface. To meet others, you need to log in to the Who2chat space (= show up at a coffee break space) to the website during the next social hour. So that you will appear available to other Who2chat users. 


Best wishes,
Who2chat
*/
const sendNoAccount = async (recipient_name, recipient_email) => {
  // send email to user
  emailjs.send('service_341y0w2', 'template_a8078je', {
    'to_name': recipient_name,
    'to_email': recipient_email,
    'from_name': "Who2chat",
  }, 'p-tc2UVQHUMvyHygU')
    .then((result) => {
      console.log("update email sent ", recipient_name, recipient_email);
      // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
     
    }, (error) => {
        console.log(error.text);
    });
}


/*
Hello {{to_name}},

We are looking forward to meeting you tomorrow at the social hour! We noticed that your Who2chat profile is not complete, hence your name is not visible to others and they won't know that you're coming until you complete it!



If you are planning to attend the virtual social hour, please complete your profile at Who2chat (https://who2chat.com) today and look through other attendees. Doing it in advance allows others to know that you're coming and planning ahead :)



* Who2chat is a "virtual coffee hour" app that allows searching others' researchers who you should meet and in-app video call within the interface all in one interface. To meet others, you need to log in to the Who2chat space (= show up at a coffee break space) to the website during the next social hour. So that you will appear available to other Who2chat users. 


Best wishes,
Who2chat
 */
const sendIncomplete = async (recipient) => {
    // send email to user
    emailjs.send('service_341y0w2', 'template_a8078je', {
      'to_name': recipient.name,
      'to_email': recipient.email,
      'from_name': "Who2chat",
    }, 'p-tc2UVQHUMvyHygU')
      .then((result) => {
        console.log("update email sent ", recipient.name);
        // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
       
      }, (error) => {
          console.log(error.text);
      });
}

const sendReminder = async (recipient) => {
  // send email to user
  emailjs.send('service_341y0w2', 'template_a8078je', {
    'to_name': recipient.name,
    'to_email': recipient.email,
    'from_name': "Who2chat",
  }, 'p-tc2UVQHUMvyHygU')
    .then((result) => {
      console.log("update email sent ", recipient.name);
      // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
     
    }, (error) => {
        console.log(error.text);
    });
}

export default sendEmail;
export {sendIncomplete, sendNoAccount, sendReminder};