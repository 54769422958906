import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {appStore} from './redux/store'

import App from './App';
import './styles/main.scss';

ReactDOM.render(
  <Provider store={appStore}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
