import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firebase_database, firestore } from "../utils/database";
import createSentMessage from "../utils/createSentMessage";

import { Button, Layout, notification } from "antd";
import {MailOutlined} from '@ant-design/icons';

import { useNavigate } from "react-router";
import { IdleSessionTimeout } from "idle-session-timeout";
import AudioPlayer from 'react-h5-audio-player';
import Favicon from 'react-favicon'

const { Content } = Layout;
let listenerSet = false;
let notified = false;
let key = "";
let unsubscribe = () => {};
let host = null;
let matched = null;
let msgInit = false;

const CheckStatus = ({ children }) => {
    const auth = useSelector((state) => state.auth);
    const history = useNavigate();

    const [favicon, setFavicon] = useState("./talk-w.ico");

    useEffect(() => {
        if (auth.isLoggedIn) {
            setUserStatus();
            if (!listenerSet) {
                setMeetingListener();
                setMessageListener();
                setIdleDetector();
            }
        }

        return () => {
            console.log("unmount");
            unsubscribe();
        }
    }, []);

    const setIdleDetector = () => {
        //time out in 15 min on inactivity
        let session = new IdleSessionTimeout(15 * 60 * 1000);
        
        session.onTimeOut = () => {
            // here you can call your server to log out the user
            console.log("idle");
            const uid = auth.currentUser.uid;
            setStatusOffline(uid);
        };
        session.start();
    }

    const setStatusOffline = async (uid) => {
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${uid}`);
        await userStatusDatabaseRef.set({
            state: 'offline',
            updatedAt: Date.now(),
        });

        // make user to refresh so they status become online again 
        alert("You were away for a while and we change your status to offline. Please reload!");
        window.location.reload();
    }
    

    const setMeetingListener = async () => {
        console.log("setMeetingListener");
        listenerSet = true;

        unsubscribe = firestore.collection("meeting").doc(auth.currentUser.uid).onSnapshot(async doc => {
            const meetingData = doc.data();
            console.log(meetingData);
            if (meetingData) {
                if (meetingData.meetingNumber && meetingData.meetingNumber.length > 0 && !notified) {
                    // guest: show invitation
                    if (meetingData.status === "guest") {
                        notified = true;
                        notifyMeeting(meetingData.host, meetingData.matched, meetingData.meetingNumber, false);
                        host = meetingData.host;
                        matched = meetingData.matched;
                    }
                    // invitee
                    else if (meetingData.status === "invitee") {
                        notified = true;
                        notifyMeeting(meetingData.host, meetingData.matched, meetingData.meetingNumber, true);
                        host = meetingData.host;
                        matched = meetingData.matched;
                    }
                    // host: notify declination
                    else if (meetingData.status === "declined") {
                        notified = true;
                        notifyDeclination();
                    }
                }
                // guest: when the host canceled the invitation
                else if (meetingData.status === "canceled") {
                    // then close notification
                    if (notification.destroy) {
                        notification.destroy(key);
                        notified = false;
                    }

                    if (host) {
                        const newKey = `open${Date.now()}`;
                        
                        const currentMatched = `#${matched}`;
                        const btn = (
                            <>
                                <Button type="primary" size="small" onClick={() => window.location.href=currentMatched}>
                                Show their profile
                                </Button>
                            </>
                        );
    
                        notification.open({
                            message: 'Missed conversation',
                            description: `${host} reached out to you but seems like you missed it!`,
                            btn,
                            key: newKey,
                            duration: 0, // open forever
                            });
                    }
                    
                }
            }
        });

    }

    const setMessageListener = async () => {
        console.log("setMessageListener");

        const ref = firestore.collection("message")
            .where(
            "receiver",
            "==",
            auth.currentUser.uid
        );
        const snapshot = await ref.get();

        unsubscribe = ref.onSnapshot(async snapshot => {
            if(!msgInit) {
                msgInit = true;
                return;
              }

            snapshot.docChanges().forEach(change => {
                const data = change.doc.data();
                const newKey = `open${Date.now()}`;

                // if this user is already in a call, let them signal their intention
                if(window.location.href.includes("/chat")) {
                    const btn = (
                        <>
                        <Button className="mr-1" size="small" onClick={() => {
                            createSentMessage("The user indicated they will be wrapping up this call very soon.", data.sender, auth.currentUser.uid, auth.currentUser.displayName);notification.destroy(newKey);}}>
                            <MailOutlined/> "Will be done in a minute!" 
                        </Button>
                        <Button className="mr-1" size="small" onClick={() => {notification.destroy(newKey); }}>
                            Close 
                        </Button>
                        </>
                        );
    
                    // alert(data.message);
                    notification.open({
                        message: `Message from ${data.senderName}`,
                        description: data.message,
                        btn,
                        key: newKey, 
                        duration: 0,
                    });
                } else {
                    notification.open({
                        message: `Message from ${data.senderName}`,
                        description: data.message,
                        key: newKey, 
                        duration: 0,
                    });
                }
                
            });
        });

    }

    // host
    const redirectDeclinedUser = async (notiKey) => {
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const roomId = (await meRef.get()).data().meetingNumber;
        const roomRef = await firestore.collection("room").doc(roomId);

        await roomRef.delete();

        meRef.set({
            meetingNumber: "",
            matched: "",
            status: "",
            host: "",
        });

        
        notified = false;

        history("/");
    }

    const notifyDeclination = () => {
        const newKey = `open${Date.now()}`;
        key = newKey;
        const btn = (
            <>
              <Button type="primary" size="small" onClick={() => {redirectDeclinedUser(newKey);notification.destroy(newKey);}}>
                Confirm
              </Button>
            </>
        );
        notification.open({
            message: "Conversation cancelled",
            description: "Unfortunately, the counterpart is not available right now. We will redirect you to the home.",
            btn, 
            key: newKey, 
            duration: 0,
            onClose: () => redirectDeclinedUser(newKey)
        });
    }

    const switchBannerNotification = (isTurnOn) => {
        if(isTurnOn) {
            setFavicon(["./talk-w.ico", "./talk-w-noti2.ico"]);
            document.title = "☎️  You've got a call! "
        }
        else {
            setFavicon("./talk-w.ico");
            document.title = "Who2chat"
        }
    }

    // guest 
    const decline = async (notiKey, counterpartId, isInvite) => {
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const userRef = await firestore.collection("meeting").doc(counterpartId);

        if (!isInvite) {
            userRef.update({
                status: "declined"
            });
        }

        meRef.set({
            meetingNumber: "",
            matched: "",
            status: "",
            host: "",
        })

        notified = false;
    }

    const accept = async (notiKey, meetingNumber) => {
        const roomRef = await firestore.collection("room").doc(meetingNumber);
        const oldData = (await roomRef.get()).data();

        switchBannerNotification(false);

        if(!oldData) {
            notification.open({
                message: 'Call is over',
                description: `Looks like the chatroom no longer exists`,
                });

            notification.destroy(notiKey); 
            return;
        }

        const oldParticipants = oldData.participants;
        const newParticipants = [...new Set([auth.currentUser.uid, ...oldParticipants])];
        await roomRef.update({
            participants: newParticipants
        })

        // hack for status check
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
        userStatusDatabaseRef.set({
            state: 'online',
            updatedAt: Date.now(),
        });

        // redirect to the chat
        history("/chat"); 
        notified = false;
    }

    const notifyMeeting = (counterpart, counterpartId, meetingNumber, isInvite) => {
        notified = true;
        
        const newKey = `open${Date.now()}`;
        key = newKey;

        switchBannerNotification(true);
        // start();
        const btn = (
        <>
          <Button type="primary" className="mr-1" size="small" onClick={() => {accept(newKey, meetingNumber);notification.destroy(newKey);}}>
            Yes, I would love to
          </Button>
          <Button type="primary" size="small" onClick={() => {decline(newKey, counterpartId, isInvite);notification.destroy(newKey);}} danger>
            No, I can't
          </Button>   
          <AudioPlayer
            autoPlay={true}
            loop={true}
            style={{display:"none"}}
            src="sounds/ringtone_11s.mp3"
            onPlay={e => console.log("onPlay")}
            />  
        </>
        );
        notification.open({
          message: 'New conversation',
          description: `${counterpart} wants to talk to you. Would you like to start the conversation now?`,
          btn,
          key: newKey,
          duration: 0,
        });

        var browser_notification = new Notification(`You've got a call from ${counterpart}!`);
    };
    
    // online offline status checker
    const setUserStatus = async () => {
        const uid = auth.currentUser.uid;
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${uid}`);
        // const userStatusFirestoreRef = await firestore.doc(`settings/${uid}`);
  
        const isOffline = {
            state: 'offline',
            updatedAt: Date.now(),
        };
        
        const isOnline = {
            state: 'online',
            updatedAt: Date.now(),
        };

        await firebase_database.ref('.info/connected').on('value', async (snapshot) => {
            // if (snapshot.val() === false) {
            //     // Instead of simply returning, we'll also set Firestore's state
            //     // to 'offline'. This ensures that our Firestore cache is aware
            //     // of the switch to 'offline.'
            //     await userStatusFirestoreRef.update(isOffline);
            //     return;
            // };
        
            userStatusDatabaseRef.onDisconnect().set(isOffline).then( async () => {
                console.log("online")
                await userStatusDatabaseRef.set(isOnline);
        
                // We'll also add Firestore set here for when we come online.
                // await userStatusFirestoreRef.update(isOnline);
            });
        });
    }

    return (
        <Content>
            <Favicon url={favicon}/>
            {children}
        </Content>
    )
}

export default CheckStatus;