import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { firebase_database, firestore } from "../utils/database";
import useReferredState from "../utils/useReferredState";

import { Card, Row, Col, notification, Button, Tooltip } from "antd";
import {PhoneOutlined, ClockCircleOutlined, ImportOutlined} from '@ant-design/icons';
import Modal from "antd/lib/modal/Modal";
import MessageList from "../components/people/MessageList";

import { getSimiliarUsers, isJoinable } from "../utils/userProfiling";


let unsubscribe = () => {};
let listenerSet = false;

const Rooms = () => {
    const [rooms, setRooms] = useReferredState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [creationTime, setCreationTime] = useState(0);
    const [joinTime, setJoinTime] = useState(new Map());
    const [leaveTime, setLeaveTime] = useState(-1);

    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [meetingNumber, setMeetingNumber] = useState("");

    const [flag, setFlag] = useState(0);

    const auth = useSelector((state) => state.auth);
    const history = useNavigate();

    useEffect(() => {
        getRooms();

        if (!listenerSet) {
            listenerSet = true;
            unsubscribe = firestore.collection("room").onSnapshot(snapshot => {
                snapshot.docChanges().forEach(async change => {
                    console.log(change);
                    if (change.type === 'added' || change.type === 'modified') {
                        const me= await loadUser(auth.currentUser.uid);
                        
                        if(me.myLikeIds.length < 2) {
                            history("/settings");
                        }

                        // get participants
                        let roomData = change.doc.data();
                        if (roomData.public) {
                            const participantList = await getParticipantList(roomData.participants);
                            delete roomData.participants;

                            const newRoom = {...roomData, participants: getSimiliarUsers(me, participantList), id: change.doc.id};

                            const oldRooms = rooms.current ? rooms.current.filter(r => r.id !== change.doc.id) : [];
                            let newRooms = [newRoom, ...oldRooms]
                            newRooms.sort(compare);
                            setRooms(newRooms);
                        }
                        else {
                            const oldRooms = rooms.current ? rooms.current.filter(r => r.id !== change.doc.id) : [];
                            setRooms(oldRooms);
                        }
                    }    
                    else if (change.type === 'removed') {
                        const newRooms = rooms.current.filter(room => room.id !== change.doc.id);
                        setRooms(newRooms);
                    }
                    
                });
            });
        }

        setDisabled(false);

        return () => {
            console.log("unmount");
            listenerSet = false;
            unsubscribe();
        }
    }, []);

    const compare = ( a, b ) => {
        if ( a.participants.length < b.participants.length ){
          return -1;
        }
        if ( a.participants.length > b.participants.length ){
          return 1;
        }
        return 0;
      }

    const getRooms = async () => {
        const snapshot = await firestore.collection("room").get();
        let newRooms = snapshot.docs.map(doc => {
                                            return ({
                                                ...doc.data(), 
                                                id: doc.id,
                                            })
                                        })
                                        .filter(room => room.public === true);

        newRooms.forEach(async room => {
            console.log(room);
            const participantList = await getParticipantList(room.participants);
            room.participants = participantList;
        });

        console.log(newRooms);

        newRooms.sort(compare);

        setRooms(newRooms);
    }

    const join = async (eId) => {
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const myData = (await meRef.get()).data();

        // Case 1 and 2 -- call can't happen
        if (myData && myData.meetingNumber && myData.meetingNumber.length > 0) {
            // Case 1: i am unavailable as a guest -> keep unavailable
            if (myData.status === "guest") {
                // notify to a guest
                notification.open({
                    message: "Someone is waiting for you",
                    description: "You seem to have received an invitation from someone else. Please accept or decline it first."
                });
                return;
            }

            // Case 2: i am unavailable with other status -> change me to available
            // this is room that my data has, instead of room I am trying to join now
            const myRoomRef = await firestore.collection("room").doc(myData.meetingNumber);
            const myRoomInstance = myRoomRef.get();
            if (myRoomInstance.exists) {

                const myRoomData = myRoomInstance.data();
                const newParticipants = myRoomData.participants.filter(p => p !== auth.currentUser.uid);
            
                if (newParticipants.length === 0) {
                    await myRoomRef.delete();
                }
                else {
                    // delete request from the room data
                    await myRoomRef.update({
                        requests: myRoomData.requests.filter(r => r.id !== auth.currentUser.uid),
                        participants: myRoomData.participants.filter(p => p !== auth.currentUser.uid),
                    });
                }
            }

            await meRef.set({
                matched: "",
                meetingNumber: "",
                joinTime: Date.now(),
                status: "",
                host: "",
            });

            // notify to try again
            notification.open({
                message: "Temporary error",
                description: "Sorry, something went wrong. Please try again!"
            });
            return;
        }

        // Case 3: i am available
        const roomId = eId.substr(1,);
        if(!roomId) return;
        const roomRef = await firestore.collection("room").doc(roomId);
        const roomInstance = await roomRef.get();

        // Case 3-1: room does not exist
        if (!roomInstance.exists) {
            notification.open({
                message: "Room ended",
                description: "Sorry, this room just disappeared. Please try other rooms."
            });
            return;
        }

        const roomData = {...roomInstance.data(), id: roomId};

        // Case 3-2: room is full or if I'm a senior and there is already a lot of senior, not let people join
        const j = await isJoinable(roomData, auth.currentUser.uid);
        if ( !j ) {
            // decline user request
            notification.open({
                message: "Room is full",
                description: "Sorry, this room just got full."
            });

            getRooms();
            return;
        }

        // Case 3-3: join room right away
        // set meeting info 
        await meRef.set({
            matched: "",
            meetingNumber: roomId,
            joinTime: Date.now(),
            status: "talking",
            host: "",
        });

        // update room participants
        const oldParticipants = roomData.participants;
        const newParticipants = [...new Set([auth.currentUser.uid, ...oldParticipants])];
        roomRef.update({
            participants: newParticipants
        });

        // hack for status check
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
        userStatusDatabaseRef.set({
            state: 'online',
            updatedAt: Date.now(),
        });

        // redirect to the video chat room
        history("/chat");

        setLoading(false);
    }

    const showInfo = (e) => {
        setFlag(flag + 1);
        const roomId = e.currentTarget.id.substr(1,);
        const roomData = rooms.current.filter(room => room.id === roomId);
        if (roomData.length > 0) {
            // // keyword count
            // let newKeywords = [];
            // for (let participant of roomData[0].participants) {
            //     for (let word of participant.selectedInterests) {
            //         let keyword = newKeywords.filter(k => k === word);
            //         if (keyword.length > 0) {
            //             keyword[0].value++;
            //         }
            //         else {
            //             newKeywords.push({text: word, value: 1});
            //             setKeywords(newKeywords);
            //         }
            //     }
            // }
            setParticipants(roomData[0].participants);

            // for later when user clicks confirmToJoin
            setMeetingNumber(roomId);

            // show the info modal 
            setIsInfoModalVisible(true);
        }
    }

    const createRoom = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDisabled(true);

        // check if current or counterpart user is unavailable (meetingNumber)
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const me = await meRef.get();
        const myData = me.data();

        if (myData && myData.meetingNumber && myData.meetingNumber.length > 0) {
            // i am unavailable
            if (myData.status === "guest") {
                // notify to a guest
                notification.open({
                    message: "Someone is waiting for you",
                    description: "You seem to have received an invitation from someone else. Please accept or decline it first."
                });
            }
            else {
                // TODO: delete request from the room data
                const roomRef = await firestore.collection("room").doc(myData.meetingNumber);
                const roomInstance = await roomRef.get();

                if (roomInstance.exists) {
                    const roomData = roomInstance.data();
                    const newParticipants = roomData.participants.filter(p => p !== auth.currentUser.uid);

                    if (newParticipants.length === 0) {
                        await roomRef.delete();
                    }
                    else {
                        await roomRef.update({
                            requests: roomData.requests.filter(r => r.id !== auth.currentUser.uid),
                            participants: roomData.participants.filter(p => p !== auth.currentUser.uid),
                        });
                    }
                }

                await meRef.set({
                    matched: "",
                    meetingNumber: "",
                    joinTime: "",
                    status: "",
                    host: "",
                });

                // notify to try again
                notification.open({
                    message: "Temporary error",
                    description: "Sorry, something went wrong. Please try again!"
                });
            }
        }
        else {
            const meetingNumber = auth.currentUser.uid + Math.floor(Math.random() * 500);

            // update users in firestore: matched, meetingNumber
            await meRef.set({
                matched: auth.currentUser.uid,
                meetingNumber: meetingNumber,
                joinTime: Date.now(),
                status: "talking",
                host: auth.currentUser.displayName,
            });

            // create a room
            await firestore.collection("room").doc(meetingNumber).set({
                participants: [auth.currentUser.uid],
                public: true,
                requests: [],
                title: "Let's talk!",
                creationTime: Date.now(),
                lastLeave: -1,
                max: 10,
            })

            // hack for status check
            const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
            userStatusDatabaseRef.set({
                state: 'online',
                updatedAt: Date.now(),
            });

            // send user to the meeting room (meetingNumber, role == host)
            history("/chat");
        }
           
        // e.currentTarget.disabled = false;
        setDisabled(false);

    }

    const getParticipantList = async (participantList) => {
        const promises = participantList.map(async pid => {
            const p = await loadUser(pid);
            return p;
        });

        const roomParticipants = await Promise.all(promises);

        return roomParticipants;
    }

    const loadUser = async (uid) => {
        const userRef = await firestore.collection("settings").doc(uid);
        const user = await userRef.get();
        const userData = {
            ...user.data(), 
            id: user.id,
        };
  
        return userData;
    }

    useEffect(()=>{
        async function getTime(meetingNumber){
            if(meetingNumber !== ''){
                const roomRef = await firestore.collection("room").doc(meetingNumber);
                const myRoom = (await roomRef.get()).data();
                setCreationTime(await myRoom.creationTime);
                setLeaveTime(await myRoom.lastLeave);
            }
        }
        getTime(meetingNumber);
        async function getJoinTime(){
            const joinTimes = new Map();
            for(const participant of participants){
                const pRef = await firestore.collection("meeting").doc(participant.id);
                const p = (await pRef.get()).data();
                joinTimes.set(participant.id, await p.joinTime);
            }
            setJoinTime(joinTimes);
        }
        getJoinTime();
    }, [flag]);

    function checkForPlural(numParticipants){
        if(numParticipants === 1){
            return "There is currently " + numParticipants.toString() + " participant in this room.";
        }
        return "There are currently " + numParticipants.toString() + " participants in this room.";
    }

    function milToString(milliseconds){
        const minutes = Math.floor((Date.now()-milliseconds)/(1000*60));
        const hours = Math.floor((Date.now()-milliseconds)/(1000*60*60));
        let myString = '';
        if(hours > 1){
            myString = hours.toString() + ' hours and ';
        }
        if(hours === 1){
            myString = hours.toString() + ' hour and ';
        }
        if(minutes === 0){
            return myString + "less than 1 minute ago";
        }
        if(minutes === 1){
            return myString + minutes.toString() + ' minute ago';
        }
        return myString + minutes.toString() + ' minutes ago';
    }

    function checkLeaveTime(time){
        if(time >= 0){
            return 'One participant left ' + milToString(time);
        }
        return '';
    }

    return (
        <>
            {loading ?
                "Loading..."
                :
                <>
                <Tooltip title="Create a public room for people to join!">
                    <Button id="create-btn" icon={<PhoneOutlined />} size="large" type="primary" onClick={(e) => createRoom(e)} disabled={disabled} danger>Create a Room</Button>
                </Tooltip> 
                <MessageList isPeopleTab={false} />
                <Row>
                    {rooms.current && rooms.current.map((room, i) => 
                        <Col key={i} xs={24} sm={12} md={8} lg={6}>
                            <Card title={room.title} extra={<a id={`a${room.id}`} onClick={(e) => join(`a${room.id}`)}>Join</a>}>
                                <p>
                                    <span className="text-muted">Participants:</span> &nbsp;
                                    {room.participants.map((p, i) => i == room.participants.length - 1 ? p.name : p.name + ", ")}
                                </p>
                                {/* <p>
                                    <span className="text-muted">Capacity:</span> &nbsp;
                                    <b>{room.participants.length} / {room.max}</b>
                                </p> */}
                                <Button id={`b${room.id}`} type="ghost" onClick={(e) => showInfo(e)}>More about this room</Button>
                            </Card>
                        </Col>
                    )}
                </Row>

                <Modal title="Would you like to join this conversation?" 
                    visible={isInfoModalVisible} 
                    closable={false}
                    footer={
                        <>
                            <Button type="ghost" onClick={() => setIsInfoModalVisible(false)}>Cancel</Button>
                            <Button type="primary" id={`c${meetingNumber}`} onClick={(e) => join(`c${meetingNumber}`)}>Join Now</Button>
                        </>
                    }
                >
                    <b>This room is currently public. You can join it right away if you want to.</b>
                    <br/>

                    <br></br>
                    <div><ClockCircleOutlined /> &nbsp; This room was created {milToString(creationTime)}.</div>
                    <br></br>
                    <div>{checkForPlural(participants.length)}</div>
                    <ul>
                        {participants.map((p, i) => 
                            <li key={i}>{p.name} ({p.affiliation}) &nbsp; — &nbsp; joined {milToString(joinTime.get(p.id))}:
                            <br/>{"#" + p.commonTerms.join(" #")}<br/>
                            </li>
                        )}
                    </ul>
                    <br/>
                    <div>{leaveTime >= 0 ? <ImportOutlined /> : ''} &nbsp; {checkLeaveTime(leaveTime)}</div>

                </Modal>

                {(!rooms.current || rooms.current.length === 0) && "No public room right now."}
                </>
            }
           
        </>
    )
}

export default Rooms;