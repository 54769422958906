import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firebase_database, firestore } from "../utils/database";
import useReferredState from "../utils/useReferredState";

import { getSimiliarUsers } from "../utils/userProfiling";
import { Button, notification, Tooltip } from "antd";
import { InfoCircleOutlined, ClockCircleOutlined, ImportOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";

import { isJoinable } from "../utils/userProfiling";
import createSentMessage from "../utils/createSentMessage";

let unsubscribe = () => {};

const JoinMeeting = ({ roomId, user, logHelper }) => {
    const auth = useSelector((state) => state.auth);
    const history = useNavigate();
    const [me, setMe] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [pending, setPending] = useReferredState(false);
    const [loading, setLoading] = useReferredState(false);
    const [joinEnabled, setJoinEnabled] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isJoinModalVisible, setIsJoinModalVisible] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [request, setRequest] = useState("Hello, I would like to join your call.")
    const [message, setMessage] = useState("Hi, I would love to have a chat with you! Please call me once you are available.")
    const [creationTime, setCreationTime] = useState(0);
    const [joinTime, setJoinTime] = useState(new Map());
    const [flag, setFlag] = useState(0);
    const [leaveTime, setLeaveTime] = useState(-1);

    // called once a counterpart is in another chat
    useEffect(() => {
        return () => {
            if (pending.current && !loading.current) {
                notification.open({
                    message: "This conversation just ended",
                    description: "Sorry, this room just disappeared! How about calling this user?"
                });

                initializeUser();
            }
            unsubscribe();
        }
      }, []);
    
    const initializeUser = async () => {
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        meRef.update({
            meetingNumber: "",
            status: "",
        });
    }

    // called when clicking the "check their room" button
    const join = async (e) => {
        setFlag(flag + 1);
        e.preventDefault();
        e.stopPropagation();

        setDisabled(true);

        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const myData = (await meRef.get()).data();

        if (myData && myData.meetingNumber && myData.meetingNumber.length > 0) {
            // i am unavailable
            if (myData.status === "guest") {
                // notify to a guest
                notification.open({
                    message: "Someone is waiting for you",
                    description: "You seem to have received an invitation from someone else. Please accept or decline it first."
                });
            }
            else {
                // TODO: delete request from the room data
                const roomRef = await firestore.collection("room").doc(myData.meetingNumber);
                const roomInstance = await roomRef.get();

                if (roomInstance.exists) {
                    const roomData = roomInstance.data();
                    const newParticipants = roomData.participants.filter(p => p !== auth.currentUser.uid);

                    if (newParticipants.length === 0) {
                        await roomRef.delete();
                    }
                    else {
                        await roomRef.update({
                            requests: roomData.requests.filter(r => r.id !== auth.currentUser.uid),
                            participants: roomData.participants.filter(p => p !== auth.currentUser.uid),
                        });
                    }
                }

                await meRef.set({
                    matched: "",
                    meetingNumber: "",
                    status: "",
                    host: "",
                });

                // notify to try again
                notification.open({
                    message: "Temporary error",
                    description: "Sorry, something went wrong. Please try again!"
                });
            }

            setDisabled(false);
        }
        else {
            const roomRef = await firestore.collection("room").doc(roomId);
            const roomInstance = await roomRef.get();

            // room exists
            if (roomInstance.exists) {
                const roomData = {...roomInstance.data(), "id": roomInstance.id};

                // ghost room
                if (roomData.participants.length === 0) {
                    roomRef.delete();
                    // notify to try again
                    notification.open({
                        message: "This conversation just ended",
                        description: "Sorry, this room just disappeared! How about calling this user?"
                    });
                    // modify the user 
                    const userRef = await firestore.collection("meeting").doc(user.id);
                    userRef.set({
                        matched: "",
                        meetingNumber: "",
                        status: "",
                        host: "",
                    });
                }
                // room is active
                else if (roomData.participants.length === roomData.max || !roomData.public) {
                    // show a modal to request to join
                    setIsModalVisible(true);

                    // check if they can join here
                    const d = await isJoinable(roomData, auth.currentUser.uid);
                    setJoinEnabled(d);
                }
                else {
                    // load the participants
                    const meData = await loadUser(auth.currentUser.uid);

                    setMe(meData);

                    await getParticipants(roomData.participants);

                    // show a modal to confirm that he/she wants to join
                    setIsJoinModalVisible(true);

                    // check if they can join here
                    const d = await isJoinable(roomData, auth.currentUser.uid);
                    setJoinEnabled(d);
                }
            }
            // room does not exists
            else {
                // notify to try again
                notification.open({
                    message: "This conversation just ended",
                    description: "Sorry, this room just disappeared! How about calling this user?"
                });

                // modify the user 
                const userRef = await firestore.collection("meeting").doc(user.id);
                userRef.set({
                    matched: "",
                    meetingNumber: "",
                    status: "",
                    host: "",
                })
            }
            // enable the join button again
            setDisabled(false);
        }

        logHelper("joinBtnClick", user);
    }

    const getParticipants = async (participantList) => {
        if (participantList) {
            const promises = participantList.map(async pid => {
                const p = await loadUser(pid);
                return p;
            });
    
            const roomParticipants = await Promise.all(promises);
    
            setParticipants(roomParticipants);

            async function getTime(meetingNumber){
                if(meetingNumber !== ''){
                    const roomRef = await firestore.collection("room").doc(meetingNumber);
                    const myRoom = (await roomRef.get()).data();
                    setCreationTime(await myRoom.creationTime);
                    setLeaveTime(await myRoom.lastLeave);
                }
            }
            getTime(roomId);
            async function getJoinTime(){
                const joinTimes = new Map();
                debugger;
                for(const participant of roomParticipants){
                    
                    const pRef = await firestore.collection("meeting").doc(participant.id);
                    const p = (await pRef.get()).data();
                    joinTimes.set(participant.id, await p.joinTime);
                    //console.log('*', await p.joinTime);
                }
                setJoinTime(joinTimes);
            }
            getJoinTime();
            
        }
    }

    const loadUser = async (uid) => {
        const userRef = await firestore.collection("settings").doc(uid);
        const user = await userRef.get();
        const userData = {
            ...user.data(), 
            id: user.id,
        };
  
        return userData;
    }

    const confirmToJoin = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // show loading screen
        setLoading(true);

        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const roomRef = await firestore.collection("room").doc(roomId);
        const roomData = (await roomRef.get()).data();

        // set meeting info 
        await meRef.set({
            matched: "",
            meetingNumber: roomId,
            status: "talking",
            host: "",
            joinTime: Date.now(), //this
        });

        // update room participants
        const oldParticipants = roomData.participants;
        const newParticipants = [...new Set([auth.currentUser.uid, ...oldParticipants])];
        roomRef.update({
            participants: newParticipants
        });

        // hack for status check
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
        userStatusDatabaseRef.set({
            state: 'online',
            updatedAt: Date.now(),
        });

        // redirect to the video chat room
        history("/chat");
    }

    const handleRequestChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setRequest(e.currentTarget.value);
    }

    const handleMessageChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setMessage(e.currentTarget.value);
    }

    const requestToJoin = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        console.log("requestToJoin");

        // set my status
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        await meRef.set({
            matched: "",
            meetingNumber: roomId,
            status: "queue",
            host: "",
        });

        // set listener first
        unsubscribe = meRef.onSnapshot(async doc => {
            const data = doc.data();
            console.log(data);

            if (data.status === "talking") {
                // show loading screen
                setLoading(true);
                
                const roomRef = await firestore.collection("room").doc(roomId);
                const roomData = (await roomRef.get()).data();

                // update room participants
                const oldParticipants = roomData.participants;
                const newParticipants = [...new Set([auth.currentUser.uid, ...oldParticipants])];
                const newRequests = roomData.requests.filter(r => r.id !== auth.currentUser.uid);
                
                roomRef.update({
                    participants: newParticipants,
                    requests: newRequests,
                });

                console.log(newParticipants);
                console.log(newRequests);

                unsubscribe();

                // hack for status check
                const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
                userStatusDatabaseRef.set({
                    state: 'online',
                    updatedAt: Date.now(),
                });

                // redirect to the video chat room
                history("/chat");
            }
            else if (data.meetingNumber === "") {
                const roomRef = await firestore.collection("room").doc(roomId);
                const roomData = (await roomRef.get()).data();
                const newRequests = roomData.requests.filter(r => r.id !== auth.currentUser.uid);
                
                roomRef.update({
                    requests: newRequests,
                });

                // stop showing the pending screen
                setPending(false);

                // show notification
                notification.open({
                    message: "Sorry, the room is not available at the moment",
                    description: `Unfortunately, ${user.name} is not available right now. How about leaving a message instead?`
                });

                unsubscribe();
            }
        });

        // show pending screen
        setPending(true);

        // request userId about the request
        const roomRef = await firestore.collection("room").doc(roomId);
        const oldRequests = (await roomRef.get()).data().requests || [];
        console.log(oldRequests);
        const newRequests = [{
                id: auth.currentUser.uid,
                name: auth.currentUser.displayName, 
                receiver: user.id,
                note: request
            }, ...oldRequests];

        console.log(newRequests);
        await roomRef.update({
            requests: newRequests
        });
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        console.log("sendMessage");

        createSentMessage(message, user.id, auth.currentUser.uid, auth.currentUser.displayName);

        setIsModalVisible(false);

        notification.open({
            message: "Success",
            description: "Successfully sent!"
        });
    }

    const disableDropdown = (e) => {
        
        if (e.target.tagName !== "BUTTON") {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.className === "ant-modal-wrap") {
                setIsModalVisible(false);
            }
        }
    }

    useEffect(()=>{
        
    }, [flag]);

    function checkForPlural(numParticipants){
        if(numParticipants === 1){
            return "There is currently " + numParticipants.toString() + " participant in this room.";
        }
        return "There are currently " + numParticipants.toString() + " participants in this room.";
    }

    function milToString(milliseconds){
        const minutes = Math.floor((Date.now()-milliseconds)/(1000*60));
        const hours = Math.floor((Date.now()-milliseconds)/(1000*60*60));
        let myString = '';
        if(hours > 0){
            myString = hours.toString() + ' hour(s) and ';
        }
        if(minutes === 0){
            return myString + "less than 1 minute ago";
        }
        return myString + minutes.toString() + ' minute(s) ago';
    }

    function checkLeaveTime(time){
        if(time >= 0){
            return 'One participant left ' + milToString(time);
        }
        return '';
    }

    return (
        <>
            <Tooltip placement="right" title="This user is currently in a chat room. Check if you want to join the conversation!">
                <Button type="primary" onClick={e => join(e)} disabled={disabled}>
                    <InfoCircleOutlined /> Check out their room
                </Button>
            </Tooltip>

            <div onClick={(e) => disableDropdown(e)}>
            <Modal title="Request to join the conversation" 
                visible={isModalVisible} 
                closable={false}
                footer={<Button type="ghost" onClick={() => setIsModalVisible(false)}>Cancel</Button>}
            >
                {joinEnabled ?
                    <>
                        <b>This room is currently private. Would you like to request to join this room?</b>
                        <TextArea rows={4} placeholder="Hello, I would like to join your call" onChange={handleRequestChange} value={request}/>
                        <Button type="primary" onClick={(e) => requestToJoin(e)}>Send Request</Button>
                    </>
                    :
                    <b>The room is currently full. Please reach out to the person later!</b>
                }
                
                <br/>
                <br/>
                <b>Or send them a message to {user.name} now to meet them after this conversation is over.</b>
                <TextArea rows={4} placeholder="Hi, I would love to have a chat with you! Please call me when you are done with your current conversation." onChange={handleMessageChange} value={message}/>
                <Button type="primary" onClick={(e) => sendMessage(e)}>Send</Button>
            </Modal>

            <Modal title="Would you like to join this conversation?" 
                visible={isJoinModalVisible} 
                closable={false}
                footer={
                    <>
                        <Button type="ghost" onClick={() => setIsJoinModalVisible(false)}>Cancel</Button>
                        <Button type="primary" disabled={!joinEnabled} onClick={(e) => confirmToJoin(e)}>Join Now</Button>
                    </>
                }
            >
                <div><ClockCircleOutlined /> &nbsp; This meeting started {milToString(creationTime)}.</div>
                <br></br>
                <div>{checkForPlural(participants.length)}</div>
                <br></br>
                <ul>
                    {"myLikeIds" in me && getSimiliarUsers(me, participants).map((p, i) => 
                            <li key={i}>{p.name} ({p.stage} at {p.affiliation})&nbsp; — &nbsp; joined {milToString(joinTime.get(p.id))}:<br/>
                            {"#" + p.commonTerms.join(" #")}<br/>
                            
                            </li>
                    )}
                </ul>
                <br></br>
                <div>{leaveTime >= 0 ? <ImportOutlined /> : ''} &nbsp; {checkLeaveTime(leaveTime)}</div>

                {joinEnabled ? 
                    ""
                    :
                    <div>The room is currently full. Please check back later. </div>
                }
                <br/>
                <br/>
                <br/>
                <b>Or send them a message to {user.name} now to meet them after this conversation is over.</b>
                <TextArea rows={4} placeholder="Hi, I would love to have a chat with you! Please call me when you are done with your current conversation." onChange={handleMessageChange} value={message}/>
                <Button type="primary" onClick={(e) => sendMessage(e)}>Send</Button>
            </Modal>
            </div>

            {pending.current &&
                <div id="pending">
                    <h2>Pending response from {user.name}</h2>
                    <h4>
                        You have requested to join {user.name}'s conversation.<br/>
                        Please wait until {user.name} responds.<br/>
                    </h4>
                    <h3>Please do NOT refresh the browser.</h3>
                    {/* <Button type="ghost" size="large" onClick={() => setPending(false)}>Cancel request</Button> */}
                </div>
            }

            {loading.current && 
                <div id="pending"><h2>Loading...</h2></div>
            }
        </>
    )
}

export default JoinMeeting;