import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { Button, Divider, Row, Col, Modal } from 'antd';


import {HeartFilled, HeartOutlined} from '@ant-design/icons';

import proceeding from "../components/cscw2022_papers";

import { firestore } from "../utils/database";

const sessionIdShuffled = [...Array(proceeding.sessions.length).keys()].sort(() => 0.5 - Math.random());

const PaperPick = ({likes, setLikePapers}) => {
    const auth = useSelector((state) => state.auth);

    const addLikePaper = (e) => {
        const uid = e.currentTarget.id.substring(6,);
        const newLikes = [...new Set([...likes, uid])];
        setLikePapers(newLikes);

        updatePaper(newLikes);
    }
    
    const removeLikePaper = (e) => {
        const newLikes = likes.filter(l => l !== e.currentTarget.id.substring(6,));
        setLikePapers(newLikes);

        updatePaper(newLikes);
    } 

    const updatePaper = (likes) => {
        firestore.doc(`settings/${auth.currentUser.uid}`).update({
            myLikeIds: likes
        })
    }


    return(
        <>
            <p>Pick at least two papers that you are interested in reading or attending the talks.</p>
            <div style={{overflow: "auto", height:"65vh"}}>
            {sessionIdShuffled.map(i => 
                <div>
                    <h4>{proceeding.sessions[i]['name']}</h4>
                    {proceeding.sessions[i].contentIds.map(pid => <Row>
                        <Col span={2}>
                            {likes.includes(pid) ? 
                                <Button type="link" id={`entry-${pid}`} className="anticon" onClick={e => removeLikePaper(e)}><HeartFilled /></Button>
                                : <Button type="link" id={`entry-${pid}`} className="anticon" onClick={e => addLikePaper(e)}><HeartOutlined /></Button>}
                        </Col>
                        <Col span={22}>
                            {proceeding.papers.filter(p => p["id"] == pid).map(p => <div>{p["title"]}<br/>
                                <small>{p["authors"].map((a, i) => `${proceeding["authors"][a].split(": ")[0]} (${proceeding["authors"][a].split(": ")[1]})`).join(", ")}</small><br/><br/></div>)}
                        </Col>
                    </Row>)}<Divider/>
                    </div>)}
            </div>
        </>
    );
}

export default PaperPick;