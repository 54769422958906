import React from "react";
import { Button, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from "react-redux";

import { collapseAction } from "../redux/reducers/sidebarReducer";

import { firebase_auth, firebase_database } from "../utils/database";

  
const { Sider } = Layout;

const Sidebar = () => {
    // shared state using redux
    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const collapsed = useSelector((state) => state.collapsed);

    const handleSignout = async () => {
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);

        await userStatusDatabaseRef.update({
            state: 'offline',
            updatedAt: Date.now(),
        });

        firebase_auth.signOut()
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} width={300}>
            <Menu theme="dark" mode="inline">
                {/* Notification will go here */}
                <Button type="link" style={{marginBottom: "30px", marginLeft: "-10px"}} onClick={(e) => {localStorage.setItem("collapsed", true);dispatch(collapseAction(true));}}>Close</Button>    
                <p>Welcome to Who2chat!<br/></p>
                
                <p>Who2chat is a system developed to explore ways of supporting virtual "hallway" chats. You can create a list of conference attendees who you have been wanting to talk to, such as
                     research superstars and friends. You can even make new research friends based on their publications and similar interests!</p>

                <p>Questions? 
                    Check out <a href="/faq">our FAQ</a> or <a role="button" style={{textDecoration: "underline"}} href='mailto:who2chat.com@gmail.com'>Email Us!</a></p>

                    <Button className="mt-1" onClick={() => handleSignout()} style={{float: "right"}}>Log out</Button>
            </Menu>
        </Sider>    
    )
}

export default Sidebar;