import React from "react";

const Faq = () => {
    return (
        <iframe 
            width="100%"
            height="100%"
            src="https://docs.google.com/document/d/e/2PACX-1vQUiwGZx19D-jCvdivD6N3ee9XIt1jdOCkJfADX_sfS60pvPIp3Zc6gSSj_7KvwDWuxKpiEFNNEopAp/pub?embedded=true"
        ></iframe>
    );
}

export default Faq;