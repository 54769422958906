import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { collapseAction } from "../redux/reducers/sidebarReducer";

import { Layout, Menu } from 'antd';

import {
    HeartTwoTone,
    UserOutlined,
    SettingOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    ClockCircleOutlined,
    SelectOutlined,
} from '@ant-design/icons';

import logo from "../images/logo-big-transparent.png";

const { Header } = Layout;

export const Navigation = (props) => {

  return (
    <Header className="px-0">
            <div className="fixed-navbar">
            <div className="logo" />
            <Menu className={true && "true"} mode="horizontal" selectedKeys={[1]}>
                <Menu.Item role="button" key="1" >
                    <a href="#header"><img src={logo} width="90"/></a>
                </Menu.Item>
                {/* <Menu.Item role="button" key="2" icon={<VideoCameraOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/rooms" true={true}>Rooms</NavLink>
                </Menu.Item>
                <Menu.Item role="button" key="3" icon={<SettingOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/settings" true={true}>Settings</NavLink>
                </Menu.Item>
                <Menu.Item style={{float:"right"}} role="button" key="4" icon={<QuestionCircleOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/faq" true={true}>FAQ</NavLink>
                </Menu.Item> */}
                
            </Menu>
            </div>
        </Header>
  );
};
