import React from "react";
import { Col, Row } from 'antd';

import {ProfileOutlined, SearchOutlined, SwapOutlined, TeamOutlined} from '@ant-design/icons';

const style: React.CSSProperties = { padding: '8px 0' };

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>

        <Row gutter={1}>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <i className="fa" style={{display: "inline-block"}}><ProfileOutlined /></i>
                  <h3>Profile</h3>
                  <p>Researchers can create a profile by importing their publication records from an academic database and customize their profile to represent their interests.</p></div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <i className="fa" style={{display: "inline-block"}}><SearchOutlined /></i>
                  <h3>Search</h3>
                  <p>Our interface supports users to be matched with ones with similar research interests. First, Who2chat uses various signals and information in one's profile to recommend meetings based on the similarity. Second, Who2chat also supports social recommendation, where a user's colleagues can suggest useful meetings to them. </p></div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <i className="fa" style={{display: "inline-block"}}><SwapOutlined /></i>
                  <h3>Introduction</h3>
                  <p>Who2chat helps users overcome their fear of approaching others. The interface lets users provide social signals: indicating their interests in meeting particular users and expressing openness to be approached. Who2chat also permits users to ``buddy up'' with friends to approach researchers of interest as a pair, to reduce the stress of approaching a stranger alone.  </p></div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <i className="fa" style={{display: "inline-block"}}><TeamOutlined /></i>
                  <h3>Meeting</h3>
                  <p>Our interface supports time coordination around meet-ups through real-time indicators to manage meet-up time and graceful conversation-exit. Who2chat also ensures that there are not too many people in the conversation and controls the number of senior and junior members through audience configuration.</p></div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
