import React, { useState } from "react";
import { useSelector } from "react-redux";
import { firebase_database, firestore } from "../utils/database";

import { Button, notification } from "antd";
import { PhoneOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";

const CreateMeeting = ({ myId, userId, setIsSendingModalVisible, setReceiver, logHelper }) => {
    const auth = useSelector((state) => state.auth);
    const history = useNavigate();
    const [disabled, setDisabled] = useState(false)

    const showSendingModal = (key) => {
        setReceiver(userId);
        setIsSendingModalVisible(true);
        notification.destroy(key);
    }

    const createMeeting = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDisabled(true);

        // check if current or counterpart user is unavailable (meetingNumber)
        const meRef = await firestore.collection("meeting").doc(myId);
        const userRef = await firestore.collection("meeting").doc(userId);

        const me = await meRef.get();
        const user = await userRef.get();
        
        const myData = me.data();
        const userData = user.data();

        if (myData && myData.meetingNumber && myData.meetingNumber.length > 0) {
            // i am unavailable
            if (myData.status === "host" || myData.status === "queue" || myData.status === "talking") {
                // TODO: delete request from the room data
                const roomRef = await firestore.collection("room").doc(myData.meetingNumber);
                const roomInstance = await roomRef.get();

                if (roomInstance.exists) {
                    const roomData = roomInstance.data();
                    const newParticipants = roomData.participants.filter(p => p !== auth.currentUser.uid);

                    if (newParticipants.length === 0) {
                        await roomRef.delete();
                    }
                    else {
                        await roomRef.update({
                            requests: roomData.requests.filter(r => r.id !== auth.currentUser.uid),
                            participants: roomData.participants.filter(p => p !== auth.currentUser.uid),
                        });
                    }
                }
                
                await meRef.set({
                    matched: "",
                    meetingNumber: "",
                    status: "",
                    host: "",
                });
            }
            // notify to try again
            notification.open({
                message: "Temporary",
                description: "Sorry, something went wrong. Please try again!"
            });
        }
        else if (userData && userData.meetingNumber && userData.meetingNumber.length > 0) { 
            const key = `open${Date.now()}`;
            const btn = (
                <>
                    <Button type="primary" className="mr-1" size="small" onClick={() => showSendingModal(key)}>
                        Yes, I would like to
                    </Button>
                    <Button type="primary" size="small" onClick={() => notification.destroy(key)} danger>
                        No, it's okay
                    </Button>
                </>
            );

            // counterpart unavailable
            notification.open({
                message: "Sorry, this user is on another call!",
                description: "Would you like to leave a message?",
                btn, 
                key,
                duration: 0,
            });
        }
        else {  // when everything is good, create a new room
            const meetingNumber = userId + myId + Math.floor(Math.random() * 500);

            // update users in firestore: matched, meetingNumber
            await meRef.set({
                matched: userId,
                meetingNumber: meetingNumber,
                status: "host",
                host: auth.currentUser.displayName,
                joinTime: Date.now(),
            });

            // trigger change so they know they will be invited
            await userRef.set({
                matched: myId,
                meetingNumber: meetingNumber,
                status: "guest",
                host: auth.currentUser.displayName,
                joinTime: Date.now(),
            })

            // create a room
            await firestore.collection("room").doc(meetingNumber).set({
                participants: [myId],
                public: false,
                title: "Let's talk!",
                requests: [],
                max: 10,
                creationTime: Date.now(),
            })

            // hack for status check
            const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
            userStatusDatabaseRef.set({
                state: 'online',
                updatedAt: Date.now(),
            });

            // send user to the meeting room (meetingNumber, role == host)
            history("/chat");
        }
           
        // e.currentTarget.disabled = false;
        setDisabled(false);

        logHelper("callBtnClick", [], userId);

    }

    

    return (
        <>
            <Button type="primary" onClick={e => createMeeting(e)} disabled={disabled}>
                <PhoneOutlined /> Start a call
            </Button>
        </>
    )
}

export default CreateMeeting;