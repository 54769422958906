import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import React from 'react';

const { Option } = Select;

const SuggestionMaking = (props) => {
  const onFinish = (values: any) => {
    console.log('Received values of form:', values);

    props['saveSuggestion'](values['suggestions'], props['suggesteeId']);
  };

  console.log(props['suggestion']);

  return (
    <Form name="dynamic_form_nest_item" onValuesChange={(changedValues, allValues) => {props['setSuggestion'](allValues['suggestions'])}} onFinish={onFinish} autoComplete="off" initialValues={{suggestions: props['suggestion']}}>
      <Form.List name="suggestions">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space  style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                name={[name, 'name']}
                  rules={[{ required: true, message: 'Missing name' }]}
                  key={key}
                >
                <Select
                allowClear
                labelInValue
                style={{ width: '200px' }}
                placeholder="Introduce to.."
                filterOption={(input, option) =>
                    // handling option.children.toLowerCase() error  
                    option.children ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0:false
                 }
                onChange={(e)=> {
                    debugger;
                    // let recipients = [];

                    // // add recipients from group
                    // e.filter(p => p.value.startsWith("group-")).map((p) => {
                    //     recipients.push( ...relationships[p.value.split("group-")[1]].map((group_member) => users.filter(u => u.id === group_member)[0] ) )
                    // });

                    // recipients.push(...e.filter(p => !p.value.startsWith("group-")).map((p) => users.filter(u => u.id === p.value)[0] ));

                    // // remove the redundant users if any
                    // recipients = recipients.reduce((unique, o) => {
                    //     if(!unique.some(obj => obj.id === o.id)) {
                    //       unique.push(o);
                    //     }
                    //     return unique;
                    // },[]);
                }}>
                {props['users'].filter(u => u.id != props['suggesteeId']) // exclude the suggested user
                    .map(u => <Option key={u.id}>{u.name}</Option>)}
                
            </Select>
                </Form.Item>
                <Form.Item
                name={[name, 'topics']}
                    label="What they should talk about"
                    rules={[{ message: 'Please input topics!' }]}
                >
                    <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button style={{width: "500px"}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add 
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
      <Button type="primary" htmlType="submit" disabled={props['suggestion'].filter(s => s && s.name && s.name.label).length == 0}>
        Introduce {props['suggestion'].filter(s => s &&  s.name && s.name.label).map(s => s.name.label).join(", ")}
      </Button>
    </Form.Item>
    </Form>
  );
};

export default SuggestionMaking;