// create constant
export const COLLAPSE = "COLLAPSE";

// create action
export const collapseAction = () => {
    return {
        type: COLLAPSE,
        payload: {}
    }
};

// create reducer
export const sidebarReducer = (state = JSON.parse(localStorage.getItem("collapsed")) || false, action) => {
    switch (action.type) {
        case COLLAPSE:
            return !state;
        default:
            return state
    }
}