import React from "react";

import { Col, Row } from 'antd';

import soya from "../images/soya.jpeg";
import jaeyoon from "../images/jaeyoon.jpeg";
import tom from "../images/tom.jpeg";
import david from "../images/david.jpeg";

const style: React.CSSProperties = { padding: '8px 0' };

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec. */}
          </p>
        </div>
        <Row gutter={1}>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <img className="team-img" src={soya}/>
                  <div className="caption">
                  <h4>Soya Park</h4>
                  <p style={{color:"gray"}}>MIT CSAIL</p>
                  </div>
            </div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <img className="team-img" src={jaeyoon}/>
                  <div className="caption">
                  <h4>Jaeyoon Song</h4>
                  <p style={{color:"gray"}}>MIT Sloan</p>
                  </div>
            </div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <img className="team-img" src={tom}/>
                  <div className="caption">
                  <h4>Thomas Malone</h4>
                  <p style={{color:"gray"}}>MIT Sloan</p>
                  </div>
            </div>
          </Col>
          <Col className="gutter-row" xs={{ span: 24, offset: 1 }} lg={{ span: 5, }}>
            <div style={style}>
                  <img className="team-img" src={david}/>
                  <div className="caption">
                  <h4>David Karger</h4>
                  <p style={{color:"gray"}}>MIT CSAIL</p>
                  </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
