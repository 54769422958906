import { notification } from "antd";

class Notification {
    key = '';
  
    open(config) {
      this.key = config.key ? config.key : `notification-${Date.now()}`;
  
      notification.open({
        ...config,
        key: this.key,
      });
    }
  
    close() {
      console.log("close ", this.key);
      notification.destroy(this.key);
    }
}

export { Notification };