// create constant
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REFRESH_USER = "REFRESH_USER";

// create action
export const loginUserAction = (user) => {
    return {
        type: LOGIN_USER,
        payload: user,
    }
};

export const logoutUserAction = () => {
    return {
        type: LOGOUT_USER,
        payload: {}
    }
};

export const refreshUserAction = (user) => {
    return {
        type: REFRESH_USER,
        payload: user,
    }
}

// create reducer
export const authReducer = (state={}, action) => {

    const user = action.payload;

    switch(action.type){
        case LOGIN_USER:
            return { ...state, 
                currentUser: {
                    displayName: user.displayName,
                    affiliation: user.affiliation,
                    uid: user.uid,
                    email: user.email,
                    updateProfile: (args) => user.updateProfile(args),
                },
                isLoggedIn: true, 
            }
        case LOGOUT_USER:
            return { ...state, 
                currentUser: null,
                isLoggedIn: false, 
            }
        case REFRESH_USER:
            return {... state,
                currentUser: user,
                isLoggedIn: true,
            }
        default:
            return state;
    }
}