import React from "react";
import { Layout } from "antd";

import AppRouter from "./Router";

import "./utils/logging.js"

function App() {
  return (
    <div className="App">
      <Layout>
        <AppRouter />
      </Layout>
    </div>
  );
}

export default App;
