import { firebase_instance, firestore } from "../utils/database";

const createSentMessage = async (message, receiver, sender, senderName) => {
    const ref = await firestore.collection("message").doc();
    
    ref.set({
        message: message,
        receiver: receiver,
        sender: sender,
        senderName: senderName,
        date: new Date()
    });

    const receiverRef = firestore.collection('settings').doc(receiver);
    receiverRef.set({
        wantsToMeet: firebase_instance.firestore.FieldValue.arrayUnion(sender)
    }, {merge: true});
    debugger;
}
    

  
export default createSentMessage;