import React, { useState, useEffect } from "react";

import '../styles/nivo-lightbox/nivo-lightbox.css';
import '../styles/nivo-lightbox/default.css';
import '../styles/landing.css';

import { Navigation } from "../landing/navigation";
import { Header } from "../landing/header";
import { Features } from "../landing/features";
import { About } from "../landing/about";
import { Services } from "../landing/services";
import { Gallery } from "../landing/gallery";
import { Testimonials } from "../landing/testimonials";
import { Team } from "../landing/Team";
import { Contact } from "../landing/contact";
import JsonData from "../landing/data.json";
import SmoothScroll from "smooth-scroll";
// import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Landing = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <Team data={landingPageData.Team} />
      <Contact/>
    </div>
  );
};

export default Landing;