const userModel = {
    selectedMatch: [],
    affiliation: "",
    author: "",
    myLikeIds: [],
    prev_papers: {},
    colleagues: [],
    relationship: {},
    wantsToMeet: [],
    myLikeIds: [],
    verified: false,
    name: "",
    email: "", 
};

export default userModel;