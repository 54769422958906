import React from "react";
import { Button, Layout, Row, Col, Modal } from 'antd';

import { firebase_auth, firebase_instance } from "../utils/database";

// import AuthForm from "../components/auth/AuthForm";
import AuthSocialForm from "../components/auth/AuthSocialForm.js";
import logo from "../images/logo-big-transparent.png";
import illustration from "../images/illustration.png";

const { Header } = Layout;

const Auth = () => {

    const countDown =() => {
        let secondsToGo = 3;
        const modal = Modal.success({
          title: `Taking you to ACM in ${secondsToGo} second`,
          content: `You will redirected to Who2chat once logged in`,
          okText: "Cancel",
          okType: "default"
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            title: `Taking you to ACM in ${secondsToGo} second`,
          });
        }, 1000);

        

        setTimeout(() => {
          clearInterval(timer);
          window.location.replace("https://programs.sigchi.org/login?callback=https://who2chat.com/");
        }, secondsToGo * 1000);
      }

    return (
        <Row>
            <Col xs={24} md={12}>
                <Header className="px-0">
                  {<a href="/home"><img height="60" src={logo} alt="Who2Chat logo - two people holding hands in a shape of green heart" /></a> }
                </Header>

                <div className="mobile-alert">
                  Who2chat currently does not fully support mobile devices. We recommend you to use a desktop or tablet device. Thank you. 
                </div>
                
                <div className="full-height">
                    {/* <AuthZoomForm /> */}
                    {/* <AuthForm /> */}
                    <img style={{"width": "200px"}} src={logo} alt="Who2Chat logo - two people holding hands in a shape of green heart " />
                    {/* <Divider plain>or</Divider>
                    Are you new? 
                    */}
                    {/* <div>
                        <Button type="primary" onClick={countDown} name="google">Log in with ACM</Button>
                    </div> */}
                    <br/>
                    <AuthSocialForm />
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div className="bg-primary full-height">
                    <img src={illustration} alt="people chatting" />
                    <a className="fixed-right" href="http://www.freepik.com"><small>Designed by stories / Freepik</small></a>
                </div>
            </Col>
        </Row>
        
    )
}

export default Auth;