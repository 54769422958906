import React from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { firebase_database, firestore } from "../../utils/database";

import { Button, Modal, notification  } from 'antd';

const BuddyCall = ({ isBuddyVisible, setIsBuddyVisible }) => {
    const auth = useSelector((state) => state.auth);

    const history = useNavigate();

    const createRoom = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // check if current or counterpart user is unavailable (meetingNumber)
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
        const me = await meRef.get();
        const myData = me.data();

        if (myData && myData.meetingNumber && myData.meetingNumber.length > 0) {
            // i am unavailable
            if (myData.status === "guest") {
                // notify to a guest
                notification.open({
                    message: "Someone is waiting for you",
                    description: "You seem to have received an invitation from someone else. Please accept or decline it first."
                });
            }
            else {
                // TODO: delete request from the room data
                const roomRef = await firestore.collection("room").doc(myData.meetingNumber);
                const roomInstance = await roomRef.get();

                if (roomInstance.exists) {
                    const roomData = roomInstance.data();
                    const newParticipants = roomData.participants.filter(p => p !== auth.currentUser.uid);

                    if (newParticipants.length === 0) {
                        await roomRef.delete();
                    }
                    else {
                        await roomRef.update({
                            requests: roomData.requests.filter(r => r.id !== auth.currentUser.uid),
                            participants: roomData.participants.filter(p => p !== auth.currentUser.uid),
                        });
                    }
                }

                await meRef.set({
                    matched: "",
                    meetingNumber: "",
                    status: "",
                    host: "",
                });

                // notify to try again
                notification.open({
                    message: "Temporary error",
                    description: "Sorry, something went wrong. Please try again!"
                });
            }
        }
        else {
            const meetingNumber = auth.currentUser.uid + Math.floor(Math.random() * 500);

            // update users in firestore: matched, meetingNumber
            await meRef.set({
                matched: auth.currentUser.uid,
                meetingNumber: meetingNumber,
                status: "talking",
                host: auth.currentUser.displayName,
                joinTime: Date.now(),
            });

            // create a room
            await firestore.collection("room").doc(meetingNumber).set({
                participants: [auth.currentUser.uid],
                public: false,
                requests: [],
                title: "Buddy chat",
                max: 5,
                creationTime: Date.now(),
            })

            // hack for status check
            const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
            userStatusDatabaseRef.set({
                state: 'online',
                updatedAt: Date.now(),
            });

            // ADD buddy call logging
            document.dispatchEvent(new CustomEvent("startBuddyCall", { detail: {
                user_id: auth.currentUser.uid,
                meetingNumber: meetingNumber,
            }}));

            // send user to the meeting room (meetingNumber, role == host)
            history("/chat");
        }
           

    }

    return (
    <Modal title="Buddy Call" 
        visible={isBuddyVisible} 
        closable={false}
        footer={
                    <>
                        <Button type="ghost" onClick={() => setIsBuddyVisible(false)}>Cancel</Button>
                        <Button type="primary" onClick={(e) => {e.stopPropagation();createRoom(e)}}>Start a buddy call</Button>
                    </>
                }
        >
            <span>Buddy call is a feature that helps you approach to a researcher. You can <b>buddy up</b> with your friend with a common interest to talk to senior researchers.
            <br/><br/>
            How does Buddy call work?<br/>
            1️⃣ Start a buddy call!<br/>
            2️⃣ Invite your peer/friend first to the call.<br/>
            3️⃣ Discuss with your peer who you want to talk to.<br/>
            4️⃣ Invite the person of interest<br/>
            5️⃣ Have a great chat and learn a lot from them ✨
            </span>
    </Modal>
    )
};

export default BuddyCall;